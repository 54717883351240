import { DefaultTheme } from 'styled-components'

export const defaultColors: DefaultTheme = {
  colors: {
    white: '#FFFFFF',
    blue: '#4C86EE',
    darkBlue: '#02268A',
    black: '#191919',
    green: '#00C19C',
    ligthGrey: '#F6F9FD',
    grey: '#8C8C8C',
    darkGrey: '#666666',
    yellow: '#f3cb20',
  },
}
