import type { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { defaultColors } from '../styles/themeColors'
import { GlobalStyle } from '../styles/globals'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={defaultColors}>
      <Component {...pageProps} />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default MyApp
